import { Flex } from "@chakra-ui/react";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import SnowdayHelp from "./components/SnowdayHelp";
import SnowdayIntro from "./components/SnowdayIntro";
import SnowdayMission from "./components/SnowdayMission";
import { useAnalytics } from "@/features/analytics/components/AnalyticsProvider";
import TopSearchView from "@/search/components/topSearch/TopSearchView";
import { TopSearchDataType } from "@/search/types";
import { AskSnowdayBlock } from "@/ui/askSnowday/AskSnowdayBlock";
import Hero from "@/ui/common/Hero";
interface HomeScreenProps {
  topSearches: TopSearchDataType[];
}
const HomeScreen = ({
  topSearches = []
}: HomeScreenProps) => {
  const {
    t
  } = useTranslation("titles");
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.track("Landing page visited", {
      Page: "Home"
    });
  }, []);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="screen.tsx">
        <title>{`Snowday | ${t("page-titles.home")}`}</title>
      </Head>

      <Flex as="main" minHeight={{
      base: "calc(100vh - 48px)",
      md: "calc(100vh - 56px)",
      lg: "calc(100vh - 64px)"
    }} flexDirection="column" data-sentry-element="Flex" data-sentry-source-file="screen.tsx">
        <Hero data-sentry-element="Hero" data-sentry-source-file="screen.tsx" />
        <TopSearchView data={topSearches} data-sentry-element="TopSearchView" data-sentry-source-file="screen.tsx" />
        <SnowdayIntro data-sentry-element="SnowdayIntro" data-sentry-source-file="screen.tsx" />
        <SnowdayMission data-sentry-element="SnowdayMission" data-sentry-source-file="screen.tsx" />
        <AskSnowdayBlock light data-sentry-element="AskSnowdayBlock" data-sentry-source-file="screen.tsx" />
        <SnowdayHelp data-sentry-element="SnowdayHelp" data-sentry-source-file="screen.tsx" />
      </Flex>
    </>;
};
export default HomeScreen;